import React from "react";
import styled from "styled-components";
import bgImage from "../../assets/images/about-bg.png"

// Define a styled component for the container
const SeasonsContainer = styled.div`
  display: flex;
  justify-content: space-around; /* Adjust as needed */
  background: radial-gradient(
    rgba(0, 0, 0, 0.5),   /* start color, 50% opacity */
    rgba(0, 0, 0, 1)    /* end color, 50% opacity */
  ), url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
`;

// Define a styled component for the card
const Card = styled.div`
  width: 30%; /* Adjust width as needed */
  background-color: #171717; /* Example background color */
  padding: 20px;
  margin-top: 80px;
  margin-bottom: 80px; /* Adjust margin as needed */
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 8px; /* Add border radius for rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
`;

// Define a styled component for the card title
const CardTitle = styled.h3`
  margin-top: 0;
  color: white;
`;

// Define a styled component for the card content
const CardContent = styled.p`
  margin-bottom: 0;
  color: gray;
`;

const SeasonSection = React.forwardRef<HTMLDivElement>(
  (_, ref) => {
    return (
      <SeasonsContainer ref={ref}>
        {/* Card 1: Install metamask */}
        <Card>
          <CardTitle>Install Metamask</CardTitle>
          <CardContent>Install Metamask, fund it with ETH by using your CEX of choice into the Base L2.</CardContent>
        </Card>
        
        {/* Card 2: Fund your metamask wallet with ETH on Base */}
        <Card>
          <CardTitle>Enter Uniswap</CardTitle>
          <CardContent>Follow the official links here to buy $BB on Uniswap.</CardContent>
        </Card>
        
        {/* Card 3: Enter Uniswap Link to buy $BB */}
        <Card>
          <CardTitle>Set Slippage to 2% and buy $BB</CardTitle>
          <CardContent>Enter the desired amount to buy in ETH, set slippage to 2% and press swap, your tokens will appear in your wallet.</CardContent>
        </Card>
      </SeasonsContainer>
    );
  }
);

export default SeasonSection;
