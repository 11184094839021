import { TextContentAbout } from "../../components/Textual/textual.module";
import { AboutSectionData } from "./about.data";
import {
  AboutFlexWrapper,
  AboutPoster,
  AboutSectionContainer,
} from "./about.module";
import posterPng from "../../assets/images/walter-based.png";
import React from "react";

interface AboutSectionProps {}

const AboutSection = React.forwardRef<HTMLDivElement, AboutSectionProps>(
  (_, ref) => {
    const textData = AboutSectionData;
    return (
      <>
        <AboutSectionContainer ref={ref}>
          <AboutFlexWrapper>
            <TextContentAbout>
              <TextContentAbout>{textData.body}</TextContentAbout>
            </TextContentAbout>
            <AboutPoster src={posterPng} alt="Poster" />
          </AboutFlexWrapper>
        </AboutSectionContainer>
      </>
    );
  }
);

export default AboutSection;
