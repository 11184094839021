import { StartWatchingBtn } from "../../components/buttons/buttons.module";
import logo from "../../assets/images/breaking-logo.png";
import base from "../../assets/images/base-white.png"
import { HomeContainer, HomeTextWrapper, Logo, LogoBase } from "./home.module";
import { TextContent } from "../../components/Textual/textual.module";
import React from "react";

interface HomePageProps {}
const HomePage = React.forwardRef<HTMLDivElement, HomePageProps>((_, ref) => {
  const homeText =
    "Breaking Base, introduces a revolutionary paradigm shift in the world of blockchain finance. As the flagship token of this groundbreaking ecosystem, $BB symbolizes resilience, innovation, and the relentless pursuit of breaking barriers.";

  return (
    <>
      <HomeContainer ref={ref}>
        <Logo src={logo} alt="Logo" />
        <HomeTextWrapper>
          <TextContent fontSize="80px" fontWeight={700}>
            Breaking
            <br />
            <LogoBase src={base} alt="Logo" />
          </TextContent>
          <TextContent fontSize="26px">{homeText}</TextContent>
        </HomeTextWrapper>
        <StartWatchingBtn
          border="3px"
          height="60px"
          width="200px"
          marginLeft="100px"
        >
          <TextContent fontSize="20px" fontWeight={500}>
            <a href="https://app.uniswap.org/swap?outputCurrency=0xFF0D379b0dE218d47Eff37f6e3B7924eD4AA2d0e&chain=base" target="_blank" rel="noopener noreferrer">
              Buy $BB on Uni
            </a>
          </TextContent>
        </StartWatchingBtn>
      </HomeContainer>
    </>
  );
});

export default HomePage;
